const languages = ['en', 'it'];
const catalogs = {
	en: require('./en/messages.json'),
	it: require('./it/messages.json'),
};
const defaultLanguage = 'it';

exports.defaultLanguage = defaultLanguage;
exports.languages = languages;
exports.catalogs = catalogs;
