/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from './src/components/WrapWithProvider';
require('typeface-roboto');

export const wrapRootElement = wrapWithProvider;
