import { createStore as reduxCreateStore } from 'redux';
import { catalogs, languages } from '../../i18n';
import getLang from '../../config/DetectLanguage';

const myLanguage = getLang();

const reducer = (state, action) => {
	if (action.type === `SWITCH`) {
		return Object.assign({}, state, {
			lang: action.lang,
		});
	} else if (action.type === `ADD_TRANSLATION_PAGE`) {
		state.currentTranslationPages[action.currentPage] = state.currentTranslationPages[action.currentPage] || {};

		state.currentTranslationPages[action.currentPage] = Object.assign(
			{},
			state.currentTranslationPages[action.currentPage],
			action.translationPage
		);
		return state;
	} else if (action.type === `SET_CURRENT_PAGE`) {
		return Object.assign({}, state, {
			currentPage: action.currentPage,
		});
	} else if (action.type === `RESET_TRANSLATION_PAGE`) {
		return Object.assign({}, state, {
			currentTranslationPages: {},
		});
	} else if (action.type === `TOGGLE_MOBILE_MENU`) {
		var obj = Object.assign({}, state, {
			visibility: state.visibility === 'hide' ? 'show' : 'hide',
		});

		obj.visibility === 'show'
			? document.querySelectorAll('html')[0].classList.add('noScroll')
			: document.querySelectorAll('html')[0].classList.remove('noScroll');

		return obj;
	}
	return state;
};

const initialState = {
	lang: myLanguage,
	translation: catalogs,
	languages: languages,
	currentPage: '',
	currentTranslationPages: {},
	visibility: 'hide',
};

const createStore = () => {
	const store = reduxCreateStore(reducer, initialState);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept(reducer, () => {
			store.replaceReducer(reducer);
		});
	}

	return store;
};
export default createStore;
