import browserLang from 'browser-lang';
import { defaultLanguage } from '../../i18n';

const getLang = () => {
	let myLanguage = defaultLanguage;

	if (typeof window !== 'undefined') {
		let urlLang = window.location.pathname.split('/');
		myLanguage =
			urlLang && urlLang.length > 2
				? urlLang[1]
				: browserLang({
						languages: ['it', 'en'],
						fallback: 'it',
				  });
	}
	return myLanguage;
};

export default getLang;
